import { UserTableDataModel } from "../../../app/utils/models/user/userTableConfigModel";

export const warehouseProductTableDefaultData: UserTableDataModel = {
  column: [
    {
      id: "work_name",
      name: "work_name",
      title: "Ish nomi va bo'limi",
      checked: false
    },
    {
      id: "unit",
      name: "unit",
      title: "Birligi",
      checked: true
    },
    {
      id: "quantity",
      name: "quantity",
      title: "Soni",
      checked: true
    },
    {
      id: "delivery_date",
      name: "delivery_date",
      title: "Kerak bo’lish sanasi",
      checked: true
    },
    {
      id: "created_at",
      name: "created_at",
      title: "Yaratilgan sana",
      checked: false
    },
    {
      id: "warehouse",
      name: "warehouse",
      title: "Omborxona",
      checked: true
    },
    {
      id: "status",
      name: "status",
      title: "Holati",
      checked: true
    },
    {
      id: "project",
      name: "project",
      title: "Loyiha",
      checked: true
    },
    {
      id: "creator",
      name: "creator",
      title: "Xodim",
      checked: true
    }
  ],
  size: 10,
  width_data: []
};

export const warehouseOrdersTableDefaultDataFunc = (lang?: string) => {
  const warehouseOrdersTableDefaultData: UserTableDataModel = {
    column: [
      {
        id: `unit.name.${lang}`,
        name: `unit.name.${lang}`,
        title: "Birligi",
        checked: true
      },
      {
        id: "quantity",
        name: "quantity",
        title: "Soni",
        checked: true
      },
      {
        id: "delivery_date",
        name: "delivery_date",
        title: "Kerak bo’lish sanasi",
        checked: true
      },
      {
        id: "created_at",
        name: "created_at",
        title: "Yaratilgan sana",
        checked: false
      },
      {
        id: "warehouse.name",
        name: "warehouse.name",
        title: "Omborxona",
        checked: true
      },
      {
        id: "project.name",
        name: "project.name",
        title: "Loyiha",
        checked: true
      },
      {
        id: "creator.full_name",
        name: "creator.full_name",
        title: "Xodim",
        checked: true
      },
      {
        id: "status",
        name: "status",
        title: "Holati",
        checked: true
      }
    ],
    size: 10
  };
  return warehouseOrdersTableDefaultData;
};
