import React, { useEffect, useMemo, useState } from "react";
import { FormInstance, Table, Form } from "antd";
import { ColumnsType } from "antd/lib/table";
import { cx } from "modules/common";
import { SpendingWorksData } from "modules/works";
import { useTranslation } from "react-i18next";
import { LoadingIndicator } from "components";
import { useDeleteProduct } from "features/projects/service/mutations";
import { useAppSelector } from "hooks/redux";

import PopoverShortName from "features/app/components/popover-short-name/PopoverShortName";
import TableEmpty from "features/app/components/table-empty/TableEmpty";
import ExpandedRow from "../expanded-row/expanded-row";
import ChevronDownIcon from "features/supply/assets/icons/ChevronDownIcon";

import styles from "../mechanism/index.module.scss";
import DeleteIcon from "features/app/assets/icons/DeleteIcon";

const { useWatch } = Form;

type Props = {
  form: FormInstance<any>;
  data: SpendingWorksData[];
  isLoading: boolean;
};

const CounterPart: React.FC<Props> = ({ data, form, isLoading }) => {
  const { t, i18n } = useTranslation();

  const deleteMuatation = useDeleteProduct();
  const record = useAppSelector(state => state.resourceSpendingReducer.drawer.record);
  const taskId = useWatch("task_id", form);

  const handleDelete = (id: number) => deleteMuatation.mutate({ id, task_id: record ? record?.id : taskId });

  const [expandedRowKeys, setExpandedRowKeys] = useState<number[]>([]);

  const handleRowClick = (record: SpendingWorksData) => {
    const key = record.id;
    const isExpanded = expandedRowKeys.includes(key);

    // Toggle expanded state
    if (isExpanded) {
      setExpandedRowKeys(expandedRowKeys.filter(k => k !== key));
    } else {
      setExpandedRowKeys([...expandedRowKeys, key]);
    }
  };

  const columns: ColumnsType<SpendingWorksData> = [
    {
      title: t("works.Resurs turi va nomi"),
      render: (_, record) => (
        <div className={cx("resource")}>
          <div
            style={{
              borderColor: record?.product?.resource?.color,
              color: record?.product?.resource?.color
            }}
          >
            {record?.product?.resource?.symbol[i18n.language]}
          </div>
          <span>
            <PopoverShortName title={record?.product?.name[i18n.language]} length={65} />
          </span>
        </div>
      ),
      width: "739px"
    },
    {
      title: t("works.Birligi"),
      render: (_value: any, record: SpendingWorksData) => (
        <div className="w-full">
          {record?.product ? (
            <div className="flex items-center gap-1">
              <span className="text-sm font-medium text-gray-400">{record?.unit?.symbol[i18n.language]}</span>
            </div>
          ) : (
            <span>-</span>
          )}
        </div>
      ),
      width: "220px"
    },
    {
      title: t("works.Qiymat"),
      width: "223px"
    },
    {
      title: t("works.Sarflaymiz"),
      width: "200px"
    },
    {
      title: "",
      render: (_, record) => (
        <div className={styles.delete} onClick={() => handleDelete(record?.id)}>
          <DeleteIcon />
        </div>
      ),
      width: "48px"
    }
  ];

  useEffect(() => {
    if (data && data?.length > 0) {
      setExpandedRowKeys(data?.map(el => el?.id));
    }
  }, [data]);

  return (
    <div className="flex h-full flex-col">
      <Table<SpendingWorksData>
        pagination={false}
        dataSource={data}
        columns={columns}
        rowKey={record => record?.id}
        footer={undefined}
        className={cx("footer_table", styles.mechanism_table)}
        rowClassName={styles.row}
        expandable={{
          expandedRowRender: (record, _, __, expanded) => {
            return (
              <ExpandedRow
                type="person_details"
                key={record?.id}
                form={form}
                task_product_id={record?.id}
                unit={record?.unit}
              />
            );
          },
          expandIcon: ({ expanded, onExpand, record }) => (
            <ChevronDownIcon
              rotate={expanded}
              onClick={e => {
                e.stopPropagation();

                onExpand(record, e as never);
              }}
              cursor="pointer"
            />
          ),
          expandedRowKeys,
          onExpand: (__, record) => handleRowClick(record)
        }}
        onRow={record => ({
          onClick: () => handleRowClick(record)
        })}
        loading={{
          spinning: isLoading,
          indicator: LoadingIndicator
        }}
        locale={{
          emptyText: <TableEmpty />
        }}
      />
    </div>
  );
};

export default CounterPart;
