import React from 'react';
import { FormInstance, Select, Form, SelectProps } from 'antd';
import { SpendingWorksData } from 'modules/works';
import { SelectSuffixIcon } from 'components';
import { DefaultOptionType } from 'antd/es/select';

const { useWatch } =  Form
const { Option } = Select

type Props = {
  form: FormInstance<any>;
  record: SpendingWorksData;
};

const WarehouseSelect:React.FC<Props> = ({ form,record }) => {
    const products = useWatch("product_details", form) as { id: number; unit_id: number; quantity: number | undefined;warehouse_id:number;total_quantity:number }[];

    const handleChange: SelectProps["onChange"] = (value: number, option) => {
      const arg = { ...option } as DefaultOptionType;
    
      const newTotalQuantity = arg.props?.total_quantity || 0;
      
      if(products && products?.length > 0){
       const foundOne = products?.find(el=>el?.id === record?.id && el?.unit_id ===  record?.unit?.id)
       let updatedOnes = [...products]
       if(!foundOne){
        updatedOnes = [...updatedOnes,{
          warehouse_id:value,
          total_quantity:newTotalQuantity,
          id:record?.id,
          unit_id:record?.unit?.id,
          quantity:undefined
        }]
       }else{
        updatedOnes = products?.map(el=>({
          ...(el?.id === record?.id && el?.unit_id === record?.unit?.id ? {
            ...el,
            warehouse_id:value,
            total_quantity:newTotalQuantity
          }:{
            ...el
          })
        }))
       }
      
    
      // Update the form with the modified products array
      form.setFieldsValue({
        product_details: updatedOnes,
      });
     }else{
      form.setFieldsValue({
        product_details:[...products,{
          warehouse_id:value,
          total_quantity:newTotalQuantity,
          id:record?.id,
          unit_id:record?.unit?.id
        }]
      })
     }
    };
    

  return (
    <Select defaultValue={record?.warehouses?.[0]?.id} suffixIcon={<SelectSuffixIcon />} onChange={handleChange}>
        {record?.warehouses?.map(el => <Option value={el?.id} props={{
            total_quantity:el?.total_quantity
        }}>{el?.name}</Option>)}
    </Select>
  )
}

export default WarehouseSelect