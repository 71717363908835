import React from "react";
import { UseFormReturn } from "react-hook-form";
import { UserSelectModel } from "features/app/utils/models/user/userSelectModel";
import { CompanyPersonModel } from "features/projects/utils/models/workModel";

import { AppendedEmployeesFormType } from "modules/append-employees";

import CompanyPersonsTabContent from "./company-persons-tab-content";
import UsersTabContent from "./users-tab-content";
import InventarsTabContent from "./inventars-tab-content";
import { InventorySelectModel } from "features/payment/utils/models/inventorySelectModel";

type Props = {
  users: UserSelectModel[];
  company_persons: CompanyPersonModel[];
  search: string;
  form: UseFormReturn<AppendedEmployeesFormType>;
  tab: "counterparts" | "users" | "inventar";
  isDefautlValuesDisabled?: boolean;
  inventars?: InventorySelectModel[];
};

const TabContent: React.FC<Props> = ({
  search,
  users,
  company_persons,
  form,
  tab,
  isDefautlValuesDisabled,
  inventars
}) => (
  <div className="flex h-[350px] w-[94%] flex-col items-center gap-5 overflow-y-auto px-[10px]">
    {tab === "users" && (
      <UsersTabContent users={users} search={search} form={form} isDefautlValuesDisabled={isDefautlValuesDisabled} />
    )}
    {tab === "counterparts" && (
      <CompanyPersonsTabContent
        company_persons={company_persons}
        search={search}
        form={form}
        isDefautlValuesDisabled={isDefautlValuesDisabled}
      />
    )}
    {tab === "inventar" && (
      <InventarsTabContent
        form={form}
        inventars={inventars!}
        search={search}
        isDefautlValuesDisabled={isDefautlValuesDisabled}
      />
    )}
  </div>
);

export default TabContent;
