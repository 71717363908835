import $api from "features/app/utils/helpers/axiosInstance";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { message } from "antd";
import { useTranslation } from "react-i18next";
import { SpendingWorksForm } from "../schema";
import { useQueryParams } from "hooks/useQueryParams";
import { queryParamsKeys } from "features/app/utils/constants/queryParamsKeys";
import { WorksTabEnum } from "../constants";

export const useCreateResourceSpendinWorks = (onAfterFunc: () => void, task_id: number) => {
  const { i18n } = useTranslation();
  const qc = useQueryClient();

  const { queries, severalSearchParams } = useQueryParams();
  const { statistics_type } = queries();
  const searchParams = severalSearchParams(queryParamsKeys.STATISTICS_TYPE);

  return useMutation<any, Error, { data: SpendingWorksForm }>({
    mutationFn: async data => {
      let url = "work/task-progress-create";

      const res = await $api.post(url, data?.data);

      return res.data;
    },
    onSuccess: data => {
      onAfterFunc();
      qc.invalidateQueries(["works", task_id]);
      if (statistics_type === WorksTabEnum.PROGRESSES) {
        qc.invalidateQueries(["taskProgress", searchParams]);
      }
      message.success(data.message[i18n.language]);
    }
  });
};
