import { useQuery, UseQueryOptions } from "@tanstack/react-query";
import $api from "features/app/utils/helpers/axiosInstance";

import { SpendingWorksData } from "modules/works/schema";

interface Query {
  resourceSpendingView: SpendingWorksData[];
}

interface Params extends Pick<UseQueryOptions, "enabled"> {
  task_id: number;
}

export function useGetResourceSpendingData({ enabled = false, task_id }: Params) {
  const initialValue: Query = { resourceSpendingView: [] };

  let url = `work/task-product-view?task_id=${task_id}`;

  const { data = initialValue, ...args } = useQuery<Query>(
    ["resource_spending_data", task_id],
    async () => {
      const {
        data: { data }
      } = await $api.get(url);

      return { resourceSpendingView: data };
    },
    { enabled: enabled && !!task_id }
  );

  return { ...data, ...args };
}
