import { useMemo } from "react";
import { FormInstance, Input } from "antd";
import { useWatch } from "antd/es/form/Form";
import { SpendingWorksData } from "modules/works";
import { useTranslation } from "react-i18next";

import styles from "./index.module.scss";

type Props = {
  form: FormInstance<any>;
  record: SpendingWorksData;
};

const QuantityInput = ({ form, record }: Props) => {
  const products = useWatch("product_details", form) as {
    id: number;
    unit_id: number;
    quantity: number;
    warehouse_id: number;
    total_quantity: number;
  }[];
  const { t } = useTranslation();

  const onQuantityChange = (value: string) => {
    const quantity = Number(value);

    if (quantity <= 0) {
      // Handle quantity <= 0
      const hasWarehouseId = products?.some(product => product?.warehouse_id);

      if (!hasWarehouseId) {
        // Remove the product from product_details
        form.setFieldsValue({
          product_details:
            products?.filter(product => !(product?.id === record?.id && product?.unit_id === record?.unit?.id)) || []
        });
      } else {
        // Set quantity to undefined for the specific product
        form.setFieldsValue({
          product_details:
            products?.map(product =>
              product?.id === record?.id && product?.unit_id === record?.unit?.id
                ? { ...product, quantity: undefined }
                : product
            ) || []
        });
      }
      return;
    }

    // Handle quantity > 0
    const newProduct = {
      id: record?.id,
      unit_id: record?.unit?.id,
      quantity,
      warehouse_id: record?.warehouses?.[0]?.id,
      total_quantity: record?.warehouses?.[0]?.total_quantity // Optional for initial entry
    };

    if (products?.length) {
      const existingProduct = products.find(
        product => product?.id === record?.id && product?.unit_id === record?.unit?.id
      );

      if (existingProduct) {
        // Update the existing product's quantity
        form.setFieldsValue({
          product_details: products.map(product =>
            product?.id === record?.id && product?.unit_id === record?.unit?.id ? { ...product, quantity } : product
          )
        });
      } else {
        // Add a new product
        form.setFieldsValue({
          product_details: [...products, newProduct]
        });
      }
    } else {
      // Initialize product_details with the new product
      form.setFieldsValue({
        product_details: [newProduct]
      });
    }
  };

  const getInputDefaultValue = useMemo(() => {
    let foundOne = {} as { id: number; unit_id: number; quantity: number } | undefined;

    foundOne = products?.find(product => product?.id === record?.id && product?.unit_id === record?.unit?.id);

    return foundOne?.quantity;
  }, [products]);

  const inputStatus = useMemo(() => {
    let foundOne = {} as
      | { id: number; unit_id: number; quantity: number; warehouse_id: number; total_quantity: number }
      | undefined;

    foundOne = products?.find(product => product?.id === record?.id && product?.unit_id === record?.unit?.id);

    if (getInputDefaultValue! > foundOne?.total_quantity!) {
      return "error";
    }
    return "";
  }, [getInputDefaultValue, products]);

  return (
    <Input
      className={styles.quantity_input}
      placeholder={t("works.Soni")}
      onChange={e => onQuantityChange(e.target.value)}
      status={inputStatus}
      value={getInputDefaultValue}
    />
  );
};

export default QuantityInput;
