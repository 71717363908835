import dayjs from "dayjs";
import minMax from "dayjs/plugin/minMax";
import { useEffect } from "react";

import { useAppSelector } from "../../../../../../../../../../hooks/redux";
import { GanttSection } from "../../utils/models/GanttSection";
import GanttChartFooter from "./gantt-chart-footer/GanttChartFooter";
import GanttChartHeader from "./gantt-chart-header/GanttChartHeader";
import GanttChartTask from "./gantt-chart-task/GanttChartTask";

import { useParams } from "react-router-dom";
import { useGetDetailedProject } from "../../../../../../../../service/queries";
import styles from "./ganttChart.module.scss";

dayjs.extend(minMax);

interface IProps {
  sections: GanttSection[] | undefined;
}

const GanttChart = ({ sections }: IProps) => {
  const params = useParams();
  const projectId = Number(params?.projectId);
  const { data: project } = useGetDetailedProject(Number(projectId));
  const { type } = useAppSelector(state => state.ganttReducer);

  const startDate = dayjs().startOf("year").add(-5, "year");

  const dueDate = dayjs()?.endOf("year").add(5, "year");

  const countOfDays = dueDate?.diff(startDate, "day");

  const countOfMonth = dueDate?.diff(startDate, "month");

  const countOfYear = dueDate?.diff(startDate, "year");

  useEffect(() => {
    const todayElement = document.getElementById("ganttToday");
    if (todayElement) {
      todayElement.scrollIntoView({
        behavior: "auto",
        block: "center",
        inline: "center"
      });
    }
  }, [type]);

  return (
    <div className={styles.body}>
      <div
        className={styles.container}
        id="gantt_chart"
        onScroll={e => {
          const gantt_table = document.getElementById("gantt_table");
          if (gantt_table) {
            gantt_table.scrollTo({
              top: e.currentTarget.scrollTop
            });
          }
        }}
        ref={ref => {
          const gantt_line_left = document.getElementById("gantt_line_left");
          const gantt_line_right = document.getElementById("gantt_line_right");
          const ganttToday = document.getElementById("ganttToday");
          gantt_line_left?.setAttribute("style", `height: ${ref?.offsetHeight}px;`);
          gantt_line_right?.setAttribute("style", `height: ${ref?.offsetHeight}px;`);
          ganttToday?.setAttribute("style", `height: calc(${ref?.offsetHeight}px - 102px);`);
        }}
      >
        <GanttChartHeader
          countOfDays={countOfDays || 0}
          countOfMonth={countOfMonth || 0}
          countOfYear={countOfYear || 0}
          startDate={startDate}
          dueDate={dueDate}
        />
        {sections &&
          sections
            ?.sort((a, b) => a.place - b.place)
            ?.map(item => (
              <GanttChartTask
                countOfDays={countOfDays || 0}
                countOfMonth={countOfMonth || 0}
                countOfYear={countOfYear || 0}
                key={item?.id}
                {...item}
                sectionId={item?.id}
                elementType="section"
                startDateGantt={startDate}
                dueDateGantt={dueDate}
                projectStatus={project?.status}
              />
            ))}
        <GanttChartFooter />
      </div>
      {/* <GanttChartFormat /> */}
    </div>
  );
};

export default GanttChart;
