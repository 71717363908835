import React, { useEffect, useState } from "react";
import dayjs from "dayjs";
import { Button, Drawer, Form } from "antd";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { resourceSpendingActions } from "store";
import { useAppSelector } from "hooks/redux";
import { FileListType } from "features/app/utils/models/fileListModel";
import { ResourceSpendingDrawerEnums, SpendingWorksForm, useCreateResourceSpendinWorks } from "modules/works";
import { dayjsFormats, isEmptyArr, isEmptyValue, parseLocaledString } from "modules/common";
import { UserSelectModel } from "features/app/utils/models/user/userSelectModel";

import Content from "./content";

import styles from "./index.module.scss";

const { useForm, useWatch } = Form;

const ResourceSpendingDrawer: React.FC = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [form] = useForm<SpendingWorksForm>();

  const [fileList, setFileList] = useState<FileListType[]>([]);
  const [confirmation_users, setConfirmationUsers] = useState<UserSelectModel[]>([]);
  const [currentTab, setCurrentTab] = useState<ResourceSpendingDrawerEnums>(ResourceSpendingDrawerEnums.PRODUCTS);

  const open = useAppSelector(state => state.resourceSpendingReducer.drawer?.visible);
  const record = useAppSelector(state => state.resourceSpendingReducer.drawer?.record);
  const { setOpenDrawer } = resourceSpendingActions;

  const products = useWatch("product_details", form);
  const counterparts = useWatch("person_details", form);
  const mechanisms = useWatch("mechanism_details", form);

  const handleClose = () => {
    dispatch(
      setOpenDrawer({
        visible: false,
        unitName: "",
        record: undefined
      })
    );
    setCurrentTab(ResourceSpendingDrawerEnums.PRODUCTS);
    form.resetFields();
    form.setFieldsValue({
      mechanism_details: undefined,
      person_details: undefined,
      product_details: undefined,
      file_ids: undefined,
      confirmation_user_ids: undefined
    });
    setFileList([]);
    setConfirmationUsers([]);
  };

  const mutate = useCreateResourceSpendinWorks(handleClose, record?.id as number);

  const onFinish = (values: SpendingWorksForm) => {
    const reqBody: SpendingWorksForm = {
      ...values,
      product_details: values?.product_details?.map(({ unit_id, total_quantity, ...rest }) => ({
        ...rest
      })),
      date: values?.date
        ? dayjs(values?.date, dayjsFormats.DATE).format(dayjsFormats.DATE)
        : dayjs().format(dayjsFormats.DATE),
      quantity: Number(parseLocaledString(String(values?.quantity)))
    };

    const filteredReqBody = Object.entries(reqBody)
      .filter(([key, value]) => !isEmptyValue(value))
      .reduce((result: Record<string, string | number | string[] | number[] | null>, [key, value]) => {
        result[key] = value as never;
        return result;
      }, {});

    mutate.mutate({ data: filteredReqBody as unknown as SpendingWorksForm });
  };

  const onSave = () => {
    form.submit();
  };

  const isProductsValid =
    !isEmptyArr(products) &&
    products?.some(el => el?.quantity) &&
    products?.every(el => el?.quantity <= Number(el?.total_quantity));
  const isCounterpartsValid =
    !isEmptyArr(counterparts) && counterparts?.some(el => el?.quantity) && counterparts?.some(el => el?.amount);
  const isMechanismsValid =
    !isEmptyArr(mechanisms) && mechanisms?.some(el => el?.quantity) && mechanisms?.some(el => el?.amount);

  const isDisabled = (!isProductsValid && !isCounterpartsValid && !isMechanismsValid) || mutate.isLoading;

  const footer = (
    <div className="flex items-center justify-end gap-3 p-3">
      <Button>{t("works.Yopish")}</Button>
      <Button type="primary" onClick={onSave} disabled={isDisabled} loading={mutate.isLoading}>
        {t("works.Sarf qilish")}
      </Button>
    </div>
  );

  useEffect(() => {
    if (record && Object.keys(record)?.length > 0) {
      form.setFieldValue("task_id", record?.id ?? undefined);
    } else {
      form.setFieldValue("task_id", undefined);
    }
  }, [record, form]);

  return (
    <>
      <Drawer
        open={open}
        title={t("works.Resurs sarf qilish")}
        zIndex={1000000}
        rootClassName={styles.resource_spending_drawer}
        footer={footer}
        onClose={handleClose}
      >
        <Content
          fileList={fileList}
          form={form}
          onFinish={onFinish}
          setFileList={setFileList}
          currentTab={currentTab}
          setCurrentTab={setCurrentTab}
          confirmation_users={confirmation_users}
          setConfirmationUsers={setConfirmationUsers}
        />
      </Drawer>
    </>
  );
};

export default ResourceSpendingDrawer;
