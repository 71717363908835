import React from "react";
import { Tooltip } from "antd";

import { getFirstLetter } from "../../utils/helpers/getFirstLetter";

import styles from "./notUserImage.module.scss";

type OtherProps = {
  name: string;
  title: string;
  width: number;
  color: string;
  background: string;
};

type TooltipProps = {
  zIndex?: number;
  arrow?: boolean;
};

type Props = Partial<OtherProps> &
  (
    | {
        isTooltip?: false;
      }
    | ({ isTooltip: true } & TooltipProps)
  );

const NotUserImage: React.FC<Props> = ({ name, width, isTooltip, title, background, color, ...rest }) => {
  const other = { ...rest } as TooltipProps;

  const style = {
    width: width ? `${width / 16}rem` : "2rem",
    height: width ? `${width / 16}rem` : "2rem",
    background: background || "#98a2b3",
    color: color || "#ffffff"
  };

  const children = (
    <div className={styles.username} style={style}>
      {name ? getFirstLetter(name || "-") : title}
    </div>
  );

  return isTooltip ? (
    <Tooltip zIndex={other?.zIndex} arrow={other?.arrow} title={title || name} placement="top">
      {children}
    </Tooltip>
  ) : (
    children
  );
};

export default NotUserImage;
