import React from "react";
import { Table, FormInstance, Form } from "antd";
import { ColumnsType } from "antd/es/table";
import { useTranslation } from "react-i18next";
import { SpendingWorksByIdData, SpendingWorksData } from "modules/works";
import { cx, Unit } from "modules/common";
import { LoadingIndicator } from "components";
import { useTaskUpdate } from "features/projects/pages/detailed-project/pages/estimate/pages/gantt/services/mutation";
import { useAppSelector } from "hooks/redux";
import { useGetResourceSpendingByIdData } from "modules/works/queries/use-get-resource-spending-by-id";
import { useGetCurrenySelect } from "features/app/service/queries";

import RollerBrushIcon from "features/app/assets/icons/RollerBrushIcon";
import CustomAvatar from "features/app/components/custom-avatar/CustomAvatar";
import PopoverShortName from "features/app/components/popover-short-name/PopoverShortName";
import TableEmpty from "features/app/components/table-empty/TableEmpty";
import ProductIcon from "features/supply/assets/icons/ProductIcon";
import AmountInput from "./amount-input";
import PlusCircleIcon from "features/app/assets/icons/PlusCircleIcon";
import AppendEmployees from "features/app/components/append-employees/append-employees";
import QuantityInput from "./quantity-input";

import styles from "./index.module.scss";

const { useWatch } = Form;

type Props = {
  type: "person_details" | "mechanism_details";
  unit: Unit;
  form: FormInstance<any>;
  task_product_id: number;
  footerBtnTitle?: string;
  isInventar?: boolean;
};

const ExpandedRow = ({ type, unit, form, task_product_id, footerBtnTitle, isInventar }: Props) => {
  const { i18n, t } = useTranslation();
  const record = useAppSelector(state => state.resourceSpendingReducer.drawer?.record);
  const taskUpdate = useTaskUpdate(undefined, undefined, task_product_id);
  const taskId = useWatch("task_id", form);
  const [inventarData, setInventarData] = React.useState<SpendingWorksByIdData[]>([]);

  const { resourceSpendingByIdView: data, isLoading } = useGetResourceSpendingByIdData({
    task_product_id,
    enabled: true
  });

  const { data: currencies } = useGetCurrenySelect();

  const icons: Record<string, React.ReactNode> = {
    mechanism_details: <RollerBrushIcon />,
    product: <ProductIcon />
  };

  const getName = (record: SpendingWorksByIdData) => {
    if (record?.company_person) {
      return `${record?.company_person?.name?.[0].toUpperCase()}${record?.company_person?.name?.[1].toUpperCase()}`;
    }
    if (record?.user && record?.user?.image) {
      return <CustomAvatar image={record?.user?.image} name={record?.user?.full_name} />;
    }
    if (record?.user && !record?.user?.image && record?.user?.full_name) {
      return `${record?.user?.full_name[0].toUpperCase()}${record?.user?.full_name[1].toUpperCase()}`;
    }
    if (record?.inventory) {
      return `${record?.inventory?.name?.[0].toUpperCase()}${record?.inventory?.name?.[1].toUpperCase()}`;
    }
    return "";
  };

  const columns: ColumnsType<SpendingWorksByIdData> = [
    {
      title: "",
      render: (record: SpendingWorksByIdData) => (
        <div className="flex items-center gap-3 py-2 pl-14">
          <div className="flex h-10 w-10 items-center justify-center rounded-[50%] border-2 border-solid border-white bg-gray-100 text-base font-medium text-gray-600">
            {icons[type] || getName(record)}
          </div>
          <span className="text-sm font-normal text-gray-900">
            <PopoverShortName
              title={(record?.company_person?.name || record?.user?.full_name || record?.inventory?.name) as string}
              length={20}
            />
          </span>
        </div>
      ),
      width: "700px"
    },
    {
      title: "",
      render: (_value: any, record: SpendingWorksByIdData) => (
        <div className="w-full pl-[60px]">
          {unit ? (
            <div className="flex items-center gap-1">
              <span className="text-sm font-medium text-gray-400">{unit?.symbol[i18n.language]}</span>
            </div>
          ) : (
            <span>-</span>
          )}
        </div>
      ),
      width: "246px"
    },
    {
      title: "",
      render: (_, record: SpendingWorksByIdData) => (
        <div className="pr-5">
          <QuantityInput
            type={type}
            form={form}
            record={record}
            task_product_id={task_product_id}
            currencies={currencies}
            key={`${
              type === "person_details"
                ? record?.user
                  ? record?.user?.id
                  : record?.company_person?.id
                : record?.inventory?.id
            }${record?.currency?.id}`}
          />
        </div>
      ),
      width: "223px"
    },
    {
      title: "",
      render: (_, record: SpendingWorksByIdData) => (
        <div className="pr-5">
          <AmountInput
            type={type}
            form={form}
            record={record}
            task_product_id={task_product_id}
            currencies={currencies}
            key={`${
              type === "person_details"
                ? record?.user
                  ? record?.user?.id
                  : record?.company_person?.id
                : record?.inventory?.id
            }${record?.currency?.id}`}
          />
        </div>
      ),
      width: "223px"
    }
  ];

  const users = data?.map(el => el?.user)?.filter(el => el);
  const companyPersons = data?.map(el => el?.company_person)?.filter(el => el);
  const inventors = (inventarData && inventarData?.length > 0 ? [...data, ...inventarData] : data)
    ?.map(el => el?.inventory)
    ?.filter(el => el);

  const onUsersAndCompanyPersonsChange = ({
    user_ids,
    company_person_ids
  }: {
    user_ids: number[];
    company_person_ids: number[];
  }) => {
    return taskUpdate.mutateAsync({ user_ids, company_person_ids, id: record?.id || taskId });
  };
  const footer = () => (
    <div className={styles.append_product}>
      <AppendEmployees
        isDefaultValuesDisabled
        isInventar={isInventar}
        users={users as never}
        company_persons={companyPersons as never}
        inventars={inventors as never}
        updateEmployees={onUsersAndCompanyPersonsChange}
        setInventar={setInventarData}
      >
        <PlusCircleIcon fill />
        <span>{footerBtnTitle || t("warehouse.Inson qo'shish")}</span>
      </AppendEmployees>
    </div>
  );

  return (
    <Table
      key={task_product_id}
      pagination={false}
      footer={footer}
      columns={columns as never}
      dataSource={isInventar && inventarData && inventarData?.length > 0 ? [...data, ...inventarData] : (data as never)}
      locale={{
        emptyText: <TableEmpty />
      }}
      loading={{
        spinning: isLoading,
        indicator: LoadingIndicator
      }}
      className={cx(styles.expanded_table, "footer_table")}
      scroll={{ y: 300 }}
    />
  );
};

export default ExpandedRow;
