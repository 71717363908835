import React, { useMemo } from "react";
import { Controller, UseFormReturn, useWatch } from "react-hook-form";
import { Checkbox, Spin } from "antd";
import NotUserImage from "features/app/components/not-image/NotUserImage";
import PopoverShortName from "features/app/components/popover-short-name/PopoverShortName";
import TableEmpty from "features/app/components/table-empty/TableEmpty";
import { InventorySelectModel } from "features/payment/utils/models/inventorySelectModel";

import { AppendedEmployeesFormType } from "modules/append-employees";

import { LoadingIndicator } from "components";
import { useGetInventorySelect } from "features/payment/service/queries";

import styles from "./inde.module.scss";
import RollerBrushIcon from "features/app/assets/icons/RollerBrushIcon";

type Props = {
  inventars: InventorySelectModel[];
  search: string;
  form: UseFormReturn<AppendedEmployeesFormType>;
  isDefautlValuesDisabled?: boolean;
};

const InventarsTabContent: React.FC<Props> = ({ inventars, search, form, isDefautlValuesDisabled }) => {
  const { data: inventorySelect, isLoading } = useGetInventorySelect(2, true);

  const selectedIds = useWatch({
    control: form.control,
    name: "inventar_ids",
    defaultValue: inventars?.map(el => el?.id)
  });

  const generateUserData: {
    id: number;
    name: string;
    image: string | null;
  }[] = useMemo(() => {
    if (search) {
      return inventorySelect
        ? inventorySelect
            ?.filter(el => el?.name?.toLowerCase().includes(search.toLowerCase()))
            ?.map(item => ({
              id: item.id,
              name: item.name,
              image: ""
            }))
        : [];
    }
    return inventorySelect
      ? inventorySelect?.map(item => ({
          id: item.id,
          name: item.name,
          image: ""
        }))
      : [];
  }, [inventorySelect, search]);

  return (
    <Spin spinning={isLoading} indicator={LoadingIndicator} wrapperClassName={styles.spinner}>
      <div className="flex h-full w-full flex-col items-center gap-5">
        {generateUserData && generateUserData?.length > 0 ? (
          generateUserData?.map((item, index) => (
            <div className="flex w-full items-center justify-between" key={item?.id}>
              <div className="flex items-center gap-[10px]">
                {item?.image ? (
                  <img src={item?.image as string} alt={item?.name?.[0]} className={styles.avatar} loading="lazy" />
                ) : (
                  <div className="flex h-10 w-10 items-center justify-center rounded-[50%] border-2 border-solid border-white bg-gray-100 text-base font-medium text-gray-600">
                    <RollerBrushIcon />
                  </div>
                )}
                <span className="text-sm font-normal text-gray-900">
                  <PopoverShortName title={item?.name} length={35} />
                </span>
              </div>
              <Controller
                render={({ field }) => (
                  <Checkbox
                    {...field}
                    checked={selectedIds?.includes(item.id)}
                    disabled={isDefautlValuesDisabled && inventars?.some(person => person.id === item.id)} // Disable if the item is part of the default values
                    onChange={e => {
                      const newValue = e.target.checked
                        ? [...(selectedIds as never), item.id]
                        : selectedIds?.filter(id => id !== item.id);

                      field.onChange(newValue);
                    }}
                  />
                )}
                name="inventar_ids"
                control={form.control}
              />
            </div>
          ))
        ) : (
          <div className="flex h-full w-full items-center justify-center">
            <TableEmpty />
          </div>
        )}
      </div>
    </Spin>
  );
};

export default InventarsTabContent;
