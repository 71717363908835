import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { TaskSelectModel } from "features/projects/utils/models/taskSelectModel";
import { WorkModel } from "features/projects/utils/models/workModel";

export interface InitialStateModel {
  drawer: {
    unitName: string;
    visible: boolean;
    record: WorkModel | undefined;
  };
}

const initialState: InitialStateModel = {
  drawer: {
    visible: false,
    unitName: "",
    record: undefined
  }
};

const resourceSpendingSlice = createSlice({
  initialState,
  name: "resource-spending",
  reducers: {
    setOpenDrawer: (
      state,
      action: PayloadAction<{
        visible: boolean;
        unitName: string;
        record: WorkModel | undefined;
      }>
    ) => {
      state.drawer = action.payload;
    }
  }
});

export default resourceSpendingSlice.reducer;
export const resourceSpendingActions = resourceSpendingSlice.actions;
