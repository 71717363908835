import React from "react";
import { FileListType } from "features/app/utils/models/fileListModel";
import { FormInstance, Form, DatePicker, Tooltip } from "antd";
import { useTranslation } from "react-i18next";
import { dayjsFormats } from "modules/common";
import { CalendarPlusIcon } from "components";
import { AppendEmployee } from "features/app/components/append-employee/AppendEmployee";
import { UserSelectModel } from "features/app/utils/models/user/userSelectModel";

import dayjs from "dayjs";
import CustomUpload from "features/app/components/custom-upload/CustomUpload";
import TextArea from "antd/es/input/TextArea";
import NotUserImage from "features/app/components/not-image/NotUserImage";
import AvatarAddIcon from "features/projects/assets/icons/AvatarAddIcon";

import styles from "./index.module.scss";

const { Item } = Form;

type SetUserType = (data: UserSelectModel | UserSelectModel[] | undefined) => undefined;

type Props = {
  form: FormInstance<any>;
  fileList: FileListType[];
  setFileList: React.Dispatch<React.SetStateAction<FileListType[]>>;
  confirmation_users:UserSelectModel[]
  setConfirmationUsers:React.Dispatch<React.SetStateAction<UserSelectModel[]>>
};

const Right: React.FC<Props> = ({ fileList, setFileList, form,confirmation_users,setConfirmationUsers }) => {
  const { t } = useTranslation();
  return (
    <div className="w-[20%] h-full overflow-y-auto box-border border-b-0 border-l border-r-0 border-t-0 border-solid border-l-gray-200 bg-white px-4 py-3">
      <div className="flex flex-col gap-4">
        <Item label={t("warehouse.Sana")} name="date">
          <DatePicker
            placeholder={t("warehouse.Tanlang")}
            format={dayjsFormats.DATE}
            suffixIcon={<CalendarPlusIcon />}
            defaultValue={dayjs()}
          />
        </Item>
        <div className="flex flex-col gap-[6px]">
          <span className="text-sm font-medium text-[#344054]">{t("works.Guvohlar")}</span>
          <AppendEmployee
            name="confirmation_user_ids"
            data={confirmation_users}
            setData={setConfirmationUsers as SetUserType}
            formInstance={form}
            title={t("works.Guvohlar")}
            description={t("works.Guvohlar yo'q,iltimos yangi yarating")}
          >
            <Item name="confirmation_user_ids">
              <div className="flex w-max content-center items-center justify-start gap-2">
                {confirmation_users?.length > 0 && (
                  <div className="flex items-center">
                    {confirmation_users.slice(0, 4)?.map(el => (
                      <div key={el?.id} className={styles.participant}>
                        {el?.image ? (
                          <img src={el?.image} alt={el?.full_name} />
                        ) : (
                          <NotUserImage name={el?.full_name} isTooltip={false} />
                        )}
                      </div>
                    ))}
                    {confirmation_users && confirmation_users?.length > 4 && (
                      <div className={styles.participant}>
                        <NotUserImage isTooltip={false} title={`+4`} />
                      </div>
                    )}
                  </div>
                )}
                <div className={styles.append}>
                  <Tooltip title={t("works.Qo'shish")}>
                    <AvatarAddIcon />
                  </Tooltip>
                </div>
              </div>
            </Item>
          </AppendEmployee>
        </div>
        <Item label={t("warehouse.Izoh")} name="description">
          <TextArea placeholder={t("warehouse.Ipoh kiting")} />
        </Item>
        <CustomUpload
          isArray
          form={form}
          name="file_ids"
          fileList={fileList}
          setFileList={setFileList}
          description={t("warehouse.Fayl yuklash uchun bosing")}
        />
      </div>
    </div>
  );
};

export default Right;
