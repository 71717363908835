import React, { BaseSyntheticEvent } from "react";
import { FieldErrors, UseFormReturn } from "react-hook-form";
import { Button, Tabs } from "antd";
import { UserSelectModel } from "features/app/utils/models/user/userSelectModel";
import { CompanyPersonModel } from "features/projects/utils/models/workModel";
import { useTranslation } from "react-i18next";

import { AppendedEmployeesFormType } from "modules/append-employees";
import { SuccessRes } from "modules/common";

import TabContent from "./tab-content";

import styles from "./inde.module.scss";
import { useGetInventorySelect } from "features/payment/service/queries";
import { InventorySelectModel } from "features/payment/utils/models/inventorySelectModel";

type Props = {
  search: string;
  users: UserSelectModel[];
  company_persons: CompanyPersonModel[];
  form: UseFormReturn<AppendedEmployeesFormType>;
  updateEmployees: ({
    user_ids,
    company_person_ids
  }: {
    user_ids: number[];
    company_person_ids: number[];
  }) => Promise<SuccessRes>;
  setPopoverOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setSearch: React.Dispatch<React.SetStateAction<string>>;
  setInventar?: React.Dispatch<React.SetStateAction<any[]>>;
  isDefaultValuesDisabled?: boolean;
  isInventar?: boolean;
  inventars?: InventorySelectModel[];
};

const AppendEmployees: React.FC<Props> = props => {
  const { t } = useTranslation();
  const {
    setPopoverOpen,
    updateEmployees,
    setSearch,
    isDefaultValuesDisabled,
    isInventar,
    setInventar,
    inventars,
    ...rest
  } = props;
  const { data: inventorySelect } = useGetInventorySelect(2, true);

  const [tab, setTab] = React.useState<"counterparts" | "users" | "inventar">(
    !isInventar ? "counterparts" : "inventar"
  );
  const onTabChange = (tab: "counterparts" | "users" | "inventar") => {
    setTab(tab);
  };

  const tabItems = [
    {
      key: "counterparts",
      label: t("appendEmployees.Kontragent"),
      children: <TabContent {...rest} isDefautlValuesDisabled={isDefaultValuesDisabled} tab={tab} />,
      isView: !isInventar
    },
    {
      key: "users",
      label: t("appendEmployees.Xodim"),
      children: <TabContent {...rest} isDefautlValuesDisabled={isDefaultValuesDisabled} tab={tab} />,
      isView: !isInventar
    },
    {
      key: "inventar",
      label: t("appendEmployees.Inventar"),
      children: (
        <TabContent {...rest} isDefautlValuesDisabled={isDefaultValuesDisabled} inventars={inventars} tab={tab} />
      ),
      isView: isInventar
    }
  ].filter(el => el?.isView);

  const onSubmit = async (
    values: AppendedEmployeesFormType,
    event: BaseSyntheticEvent<object, any, any> | undefined
  ) => {
    event?.stopPropagation(); // Prevent bubbling to the parent form
    const { inventar_ids, ...rest } = values;

    if (inventar_ids && inventar_ids.length > 0 && isInventar && setInventar) {
      const filteredInventar = inventorySelect
        ?.filter(item => inventar_ids.includes(item.id))
        ?.map(el => ({
          inventory: {
            id: el?.id,
            name: el?.name
          }
        }));

      setInventar(prev => {
        // Combine the previous state with the filtered result
        return inventars && inventars?.length > 0
          ? [
              ...prev,
              ...(filteredInventar?.filter(el => !inventars?.some(item => item?.id === el?.inventory?.id)) as never)
            ]
          : (filteredInventar as never);
      });
      setPopoverOpen(false);
      setSearch("");
      setTab("inventar");
    } else {
      await updateEmployees(rest).then(() => {
        setPopoverOpen(false);
        setTab("counterparts");
        setSearch("");
      });
    }
  };

  const onError = (error: FieldErrors) => {
    console.log(error);
  };

  return (
    <form
      className="w-full p-0"
      onSubmit={e => {
        e.stopPropagation();
        props.form.handleSubmit(onSubmit, onError)(e);
      }}
    >
      <Tabs
        items={tabItems}
        onChange={onTabChange as never}
        activeKey={tab}
        tabBarExtraContent={<div className="p-3 text-white">Keraksiz</div>}
        rootClassName={styles.tabs}
      />
      <div className="flex items-center justify-end border-b-0 border-l-0 border-r-0 border-t border-solid border-t-gray-200 p-2">
        <Button htmlType="submit" type="primary" loading={props.form.formState.isSubmitting}>
          {t("appenEmployees.Saqlash")}
        </Button>
      </div>
    </form>
  );
};

export default AppendEmployees;
