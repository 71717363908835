import { TFunction } from "i18next";
import ResourceSpendingDrawerEnums from "./resource-spending-drawer-tab-enums";

const ResourceSpendingDrawerTabItems = (children: React.ReactNode, t: TFunction<"translation", undefined>) => [
  {
    key: ResourceSpendingDrawerEnums.PRODUCTS,
    label: t("works.Mahsulotlar"),
    children,
    forceRender: true
  },
  {
    key: ResourceSpendingDrawerEnums.COUNTERPART,
    label: t("works.Inson resursi"),
    children,
    forceRender: true
  },
  {
    key: ResourceSpendingDrawerEnums.MECHANISM,
    label: t("works.Mexanizmlar"),
    children,
    forceRender: true
  }
];

export default ResourceSpendingDrawerTabItems;
