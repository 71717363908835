import React, { useMemo, useCallback } from "react";
import { FormInstance, Form, Input, Select } from "antd";
import { useTranslation } from "react-i18next";

import { SpendingWorksByIdData } from "modules/works";
import { useGetCurrenySelect } from "features/app/service/queries";
import { SelectSuffixIcon } from "components";
import { colors } from "modules/common";
import { CurrencyModel } from "features/settings/utils/models/currency/currencyModel";

import styles from "./index.module.scss";

const { useWatch } = Form;
const { Option } = Select;

type Product = {
  id: number;
  currency_id: number;
  quantity: number | undefined;
  company_person_id?: number;
  user_id?: number;
  inventory_id?: number;
  amount: number | undefined;
};

type Props = {
  form: FormInstance<any>;
  type: "person_details" | "mechanism_details";
  record: SpendingWorksByIdData;
  task_product_id: number;
  currencies: CurrencyModel[] | undefined;
};

const AmountInput: React.FC<Props> = ({ form, type, record, task_product_id, currencies }) => {
  const products = useWatch(type, form) as Product[];
  const { t } = useTranslation();

  const findProduct = useCallback(() => {
    // if (!record?.currency?.id) return null;

    return products?.find(product =>
      type === "person_details"
        ? record?.user?.id
          ? product.user_id === record.user.id && product?.id === task_product_id
          : product.company_person_id === record?.company_person?.id && product?.id === task_product_id
        : product.inventory_id === record?.inventory?.id && product.id === task_product_id
    );
  }, [products, type, record]);

  const updateProducts = useCallback(
    (newQuantity: number, newCurrencyId?: number) => {
      const existingProduct = findProduct();

      if (newQuantity > 0 || newCurrencyId) {
        if (!existingProduct) {
          const newProduct: Product = {
            id: task_product_id,
            amount: newQuantity || 0,
            quantity: undefined,
            currency_id: newCurrencyId || record?.currency?.id! || (currencies?.[0]?.id as number),
            ...(type === "person_details"
              ? record?.user
                ? { user_id: record.user.id }
                : { company_person_id: record?.company_person?.id as never }
              : { inventory_id: record?.inventory?.id as never })
          };
          form.setFieldsValue({ [type]: [...(products || []), newProduct] });
        } else {
          const updatedProducts = products.map(product =>
            product === existingProduct
              ? {
                  ...product,
                  amount: newQuantity || product.amount,
                  currency_id: newCurrencyId || product.currency_id
                }
              : product
          );
          form.setFieldsValue({ [type]: updatedProducts });
        }
      } else {
        let filteredProducts = [];
        if (!existingProduct?.quantity) {
          filteredProducts = products.filter(product => product !== existingProduct);
        } else {
          filteredProducts = products?.map(product =>
            product === existingProduct
              ? {
                  ...product,
                  amount: undefined
                }
              : product
          );
        }
        form.setFieldsValue({ [type]: filteredProducts });
      }
    },
    [findProduct, form, products, task_product_id, type, record]
  );

  const defaultValue = useMemo(() => findProduct()?.amount || "", [findProduct]);
  const selectedCurrency = useMemo(
    () => findProduct()?.currency_id || record?.currency?.id || currencies?.[0]?.id,
    [findProduct, record, currencies]
  );

  const handleCurrencyChange = (currencyId: number) => {
    updateProducts(undefined as never, currencyId);
  };

  return (
    <Input
      className={styles.quantity_input}
      placeholder={t("works.Summa")}
      onChange={e => updateProducts(Number(e.target.value))}
      value={defaultValue}
      addonAfter={
        <Select
          defaultValue={record?.currency?.id || currencies?.[0]?.id}
          value={selectedCurrency}
          onChange={handleCurrencyChange}
          className={styles.currency_select}
          suffixIcon={<SelectSuffixIcon stroke={colors.GRAY_400} />}
        >
          {currencies?.map(currency => (
            <Option key={currency.id} value={currency.id}>
              <span className={styles.currency_symbol}>{currency?.symbol}</span>
            </Option>
          ))}
        </Select>
      }
    />
  );
};

export default AmountInput;
