import React, { useMemo, useState } from "react";
import { Button } from "antd";
import dayjs, { Dayjs } from "dayjs";
import MainWarehouseIcon from "features/app/assets/icons/MainWarehouseIcon";
import FilterDateRange from "features/app/components/filter-drawer/filter-date-range/FilterDateRange";
import FilterSelect from "features/app/components/filter-drawer/filter-select/FilterSelect";
import FilterTreeSelect from "features/app/components/filter-drawer/filter-tree-select/filter-tree-select";
import FilterDrawer from "features/app/components/filter-drawer/FilterDrawer";
import FiltersCount from "features/app/components/filter-drawer/filters-count/FiltersCount";
import { useProjectSelect } from "features/app/service/queries";
import { dayjsFormats } from "features/app/utils/constants/dayjsFormats";
import { queryParamsKeys } from "features/app/utils/constants/queryParamsKeys";
import BuildingIcon from "features/payment/assets/icons/BuildingIcon";
import FilterLinesIcon from "features/warehouse/assets/icons/FilterLinesIcon";
import { useGetWarehouseSelect } from "features/warehouse/service/query";
import queryString from "query-string";
import { useTranslation } from "react-i18next";

import { useQueryParams } from "hooks/useQueryParams";
import CreditCardUploadIcon from "features/payment/assets/icons/CreditCardUploadIcon";

const queryStringArr = [
  queryParamsKeys.MIN_CREATED_DATE,
  queryParamsKeys.MAX_CREATED_DATE,
  queryParamsKeys.PROJECT_IDS,
  queryParamsKeys.WAREHOUSE_IDS,
  queryParamsKeys.TYPE
];

const IncomeExpenseHistoryFilterDrawer: React.FC = () => {
  const { t } = useTranslation();
  const { queries } = useQueryParams();

  const [open, setOpen] = useState(false);

  const onOpenFilter = () => {
    setOpen(true);
  };

  const [dateValue, setDateValue] = useState<null | (Dayjs | null)[]>([
    queries()[queryParamsKeys.MIN_CREATED_DATE]
      ? dayjs(queries()[queryParamsKeys.MIN_CREATED_DATE], dayjsFormats.DATE)
      : null,
    queries()[queryParamsKeys.MAX_CREATED_DATE]
      ? dayjs(queries()[queryParamsKeys.MAX_CREATED_DATE], dayjsFormats.DATE)
      : null
  ]);

  const [projectsSelected, setProjectsSelected] = useState<string[] | null>(
    // eslint-disable-next-line no-restricted-globals
    queryString.parse(location.search, {
      arrayFormat: "bracket"
    })[queryParamsKeys.PROJECT_IDS] as string[] | null
  );

  const [warehousesSelected, setWarehousesSelected] = useState<string[] | null>(
    // eslint-disable-next-line no-restricted-globals
    queryString.parse(location.search, {
      arrayFormat: "bracket"
    })[queryParamsKeys.WAREHOUSE_IDS] as string[] | null
  );

  const [typeSelected, setTypeSelected] = useState<string[] | null>(
    // eslint-disable-next-line no-restricted-globals
    queryString.parse(location.search, {
      arrayFormat: "bracket"
    })[queryParamsKeys.TYPE] as string[] | null
  );

  const { data: projects } = useProjectSelect({ enabled: open });
  const { data: warehouses } = useGetWarehouseSelect(false, open);

  const typeData = useMemo(
    () => [
      {
        id: "income",
        name: `${t("warehouse.Kirim")}`
      },
      {
        id: "expense",
        name: `${t("warehouse.Chiqim")}`
      }
    ],
    []
  );

  return (
    <FilterDrawer
      open={open}
      height="max-content"
      setOpen={setOpen}
      queryStringArr={queryStringArr}
      filterButton={
        <Button onClick={onOpenFilter}>
          <FilterLinesIcon /> {t("warehouse.Filter")}
          <FiltersCount queryStringArr={queryStringArr} />
        </Button>
      }
      dateValueArr={[
        {
          queryKey: queryParamsKeys.MIN_CREATED_DATE,
          selectedValue: dateValue && dateValue[0]
        },
        {
          queryKey: queryParamsKeys.MAX_CREATED_DATE,
          selectedValue: dateValue && dateValue[1]
        }
      ]}
      selectedOptionsArr={[
        {
          queryKey: queryParamsKeys.WAREHOUSE_IDS,
          selectedOptions: warehousesSelected
        },
        {
          queryKey: queryParamsKeys.PROJECT_IDS,
          selectedOptions: projectsSelected
        },
        {
          queryKey: queryParamsKeys.TYPE,
          selectedOptions: typeSelected
        }
      ]}
      setSelectedOptionsArr={[setWarehousesSelected, setProjectsSelected, setTypeSelected] as never}
      setDateValue={[setDateValue]}
    >
      <div className="flex flex-wrap gap-5">
        <FilterTreeSelect
          selectedOptions={projectsSelected}
          setSelectedOptions={setProjectsSelected}
          data={projects}
          multiple={true}
          icon={<BuildingIcon />}
          placeholder={t("warehouse.Loyiha")}
          showSearch
        />
        <FilterSelect
          selectedOptions={warehousesSelected}
          setSelectedOptions={setWarehousesSelected}
          data={warehouses}
          multiple="multiple"
          icon={<MainWarehouseIcon />}
          placeholder={t("warehouse.Omborxona")}
          showSearch
        />
        <FilterSelect
          selectedOptions={typeSelected}
          setSelectedOptions={setTypeSelected}
          data={typeData}
          // multiple="multiple"
          // icon={<CreditCardUploadIcon />}
          placeholder={t("warehouse.Turi")}
          showSearch
        />
        <FilterDateRange value={dateValue} setValue={setDateValue} />
      </div>
    </FilterDrawer>
  );
};

export default IncomeExpenseHistoryFilterDrawer;
