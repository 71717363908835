import { useQuery, UseQueryOptions } from "@tanstack/react-query";
import $api from "features/app/utils/helpers/axiosInstance";

import { SpendingWorksByIdData } from "modules/works/schema";

interface Query {
  resourceSpendingByIdView: SpendingWorksByIdData[];
}

interface Params extends Pick<UseQueryOptions, "enabled"> {
  task_product_id: number;
}

export function useGetResourceSpendingByIdData({ enabled = false, task_product_id }: Params) {
  const initialValue: Query = { resourceSpendingByIdView: [] };

  let url = `work/task-product-resource-view?task_product_id=${task_product_id}`;

  const { data = initialValue, ...args } = useQuery<Query>(
    ["resource_spending_by_id_data", task_product_id],
    async () => {
      const {
        data: { data }
      } = await $api.get(url);

      return { resourceSpendingByIdView: data };
    },
    { enabled: enabled && !!task_product_id }
  );


  return { ...data, ...args };
}
