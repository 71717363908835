import React from "react";
import { CompletedWorkModel } from "features/projects/utils/models/completedWorkModel";
import { WorkModel } from "features/projects/utils/models/workModel";
import { useTranslation } from "react-i18next";
import { TooltipShortName } from "components";
import { projectsReducerActions } from "store/reducers/projectsReducer";
import { useAppDispatch } from "hooks/redux";

type Props = {
  progressId: number;
  foundTaskProgress?: CompletedWorkModel | null;
  work?: WorkModel;
};

const Right: React.FC<Props> = ({ progressId, work, foundTaskProgress }) => {
  const { t, i18n } = useTranslation();
  const { setWork } = projectsReducerActions;
  const dispatch = useAppDispatch();

  const handleOpenWorkDrawer = () => {
    dispatch(
      setWork({
        visible: true,
        id: work?.id || foundTaskProgress?.task?.id
      })
    );
  };

  return (
    <div className="flex w-[30%] flex-col justify-center gap-3 rounded-xl border border-solid border-gray-200 bg-white p-4">
      <div className="flex w-full justify-between">
        <span className="cursor-pointer text-sm font-normal text-gray-600">{t("works.Ish nomi")}</span>
        <div className="flex items-center gap-1">
          <span className="text-sm font-normal text-gray-800">{work?.place || foundTaskProgress?.place}</span>{" "}
          <span className="cursor-pointer text-sm font-normal text-gray-800" onClick={handleOpenWorkDrawer}>
            {work?.name ? (
              <TooltipShortName title={work?.name} length={15} />
            ) : (
              <TooltipShortName title={foundTaskProgress?.task?.name} length={15} />
            )}
          </span>
        </div>
      </div>
      <div className="flex w-full justify-between">
        <span className="text-sm font-normal text-gray-600">{t("works.Ish bo'limi")}</span>
        <div className="flex items-center gap-1">
          <span className="text-sm font-normal text-gray-800">
            {work?.section?.place || foundTaskProgress?.task?.section?.place}
          </span>{" "}
          <span className="text-sm font-normal text-gray-800">
            {work?.section?.name ? (
              <TooltipShortName title={work?.section?.name} length={15} />
            ) : (
              <TooltipShortName title={foundTaskProgress?.task?.section?.name} length={15} />
            )}
          </span>
        </div>
      </div>
      <div className="flex w-full justify-between">
        <span className="text-sm font-normal text-gray-600">{t("works.Loyiha")}</span>
        <span className="text-sm font-normal text-gray-800">
          {work?.section?.project?.name ? (
            <TooltipShortName title={work?.section?.project?.name} />
          ) : (
            <TooltipShortName title={foundTaskProgress?.task?.section?.project?.name} length={15} />
          )}
        </span>
      </div>
      <div className="flex w-full justify-between">
        <span className="text-sm font-normal text-gray-600">
          {progressId ? t("works.Progres") : t("works.Ish hajmi")}
        </span>
        <span className="text-base font-semibold text-primary-500">
          {!progressId ? work?.task_progress_quantity : foundTaskProgress?.quantity} {work?.unit?.symbol[i18n.language]}
        </span>
      </div>
    </div>
  );
};

export default Right;
