import { ResourceSpendingDrawerEnums } from "modules/works";
import React, { ReactNode, useEffect, useMemo } from "react";
import { FormInstance, Form } from "antd";
import Products from "./products";
import CounterPart from "./counterpart";
import Mechanism from "./mechanism";
import { useAppSelector } from "hooks/redux";
import { useGetResourceSpendingData } from "modules/works/queries/use-get-resource-speding-data";

const { Item, useWatch } = Form;

type Props = {
  currentTab: ResourceSpendingDrawerEnums;
  form: FormInstance;
};

const TabContents: React.FC<Props> = ({ currentTab, form }) => {
  const record = useAppSelector(state => state.resourceSpendingReducer.drawer.record);
  const taskId = useWatch("task_id", form);

  const { resourceSpendingView, isLoading } = useGetResourceSpendingData({
    task_id: record?.id ?? taskId,
    enabled: true
  });

  const products = useMemo(
    () => resourceSpendingView?.filter(el => el?.product?.resource?.type === "product"),
    [resourceSpendingView]
  );
  const counterparts = useMemo(
    () => resourceSpendingView?.filter(el => el?.product?.resource?.type === "person"),
    [resourceSpendingView]
  );
  const mechanisms = useMemo(
    () => resourceSpendingView?.filter(el => el?.product?.resource?.type === "mechanism"),
    [resourceSpendingView]
  );

  const content = useMemo(
    () => ({
      [ResourceSpendingDrawerEnums.PRODUCTS]: (
        <Item
          shouldUpdate
          className="!h-full [&_.ant-form-item-control-input-content]:h-full [&_.ant-form-item-control-input]:h-full [&_.ant-form-item-control-input]:items-start [&_.ant-form-item-row]:h-full [&_.ant-form-item]:h-full"
          initialValue={[]}
        >
          <Products data={products} form={form} isLoading={isLoading} />
        </Item>
      ),
      [ResourceSpendingDrawerEnums.COUNTERPART]: (
        <Item
          shouldUpdate
          className="!h-full [&_.ant-form-item-control-input-content]:h-full [&_.ant-form-item-control-input]:h-full [&_.ant-form-item-control-input]:items-start [&_.ant-form-item-row]:h-full [&_.ant-form-item]:h-full"
          initialValue={[]}
        >
          <CounterPart data={counterparts} form={form} isLoading={isLoading} />
        </Item>
      ),
      [ResourceSpendingDrawerEnums.MECHANISM]: (
        <Item
          shouldUpdate
          className="!h-full [&_.ant-form-item-control-input-content]:h-full [&_.ant-form-item-control-input]:h-full [&_.ant-form-item-control-input]:items-start [&_.ant-form-item-row]:h-full [&_.ant-form-item]:h-full"
          initialValue={[]}
        >
          <Mechanism data={mechanisms} form={form} isLoading={isLoading} />
        </Item>
      )
    }),
    [products, counterparts, mechanisms, isLoading, form]
  );

  return <div key={currentTab}>{content[currentTab]}</div>;
};

export default TabContents;
