import React from "react";
import { FormInstance, Table, Select, Form } from "antd";
import { ColumnsType } from "antd/es/table";
import { useTranslation } from "react-i18next";
import { LoadingIndicator } from "components";
import { SpendingWorksData } from "modules/works";
import { cx } from "modules/common";
import { useDeleteProduct } from "features/projects/service/mutations";

import TableEmpty from "features/app/components/table-empty/TableEmpty";
import PopoverShortName from "features/app/components/popover-short-name/PopoverShortName";
import QuantityInput from "./quantity-input";
import WarehouseSelect from "./warehouse-select";
import DeleteIcon from "features/app/assets/icons/DeleteIcon";

import styles from "./index.module.scss";
import { useAppSelector } from "hooks/redux";

const { useWatch } = Form;

type Props = {
  data: SpendingWorksData[];
  form: FormInstance<any>;
  isLoading: boolean;
};

const Products: React.FC<Props> = ({ data, form, isLoading }) => {
  const { t, i18n } = useTranslation();
  const products = useWatch("product_details", form) as { id: number; unit_id: number; total_quantity: number }[];
  const deleteMuatation = useDeleteProduct();
  const record = useAppSelector(state => state.resourceSpendingReducer.drawer.record);
  const taskId = useWatch("task_id", form);

  const handleDelete = (id: number) => deleteMuatation.mutate({ id, task_id: record ? record?.id : taskId });

  const columns: ColumnsType<SpendingWorksData> = [
    {
      title: t("works.Resurs turi va nomi"),
      render: (_, record) => (
        <div className={cx("resource")}>
          <div
            style={{
              borderColor: record?.product?.resource?.color,
              color: record?.product?.resource?.color
            }}
          >
            {record?.product?.resource?.symbol[i18n.language]}
          </div>
          <span>
            <PopoverShortName title={record?.product?.name[i18n.language]} length={65} />
          </span>
        </div>
      ),
      width: "542px"
    },
    {
      title: t("works.Mavjud"),
      render: (_, record) => {
        const productDetails = products?.find(p => p.id === record.id && p.unit_id === record.unit?.id);

        return record?.warehouses && record?.warehouses?.length > 0 ? (
          <div>
            {productDetails ? (
              <span>{productDetails.total_quantity || record?.warehouses?.[0]?.total_quantity}</span>
            ) : (
              <span>{record?.warehouses?.[0]?.total_quantity}</span>
            )}
          </div>
        ) : (
          "-"
        );
      },
      width: "90px"
    },
    {
      title: t("works.Omborxona"),
      render: (_, record) => (
        <>
          {record?.warehouses && record?.warehouses?.length > 0 ? (
            <WarehouseSelect form={form} record={record} key={record?.id} />
          ) : (
            "-"
          )}
        </>
      ),
      width: "253px"
    },
    {
      title: t("works.Sarflaymiz"),
      render: (_, record) => (
        <QuantityInput key={`${record?.product?.id}-${record?.unit?.id}`} form={form} record={record} />
      ),
      width: "140px"
    },
    {
      title: t("works.Birligi"),
      render: (_, record) => <span>{record?.unit?.symbol?.[i18n.language]}</span>,
      width: "131px"
    },
    {
      title: "",
      render: (_, record) => (
        <div className={styles.delete} onClick={() => handleDelete(record?.id)}>
          <DeleteIcon />
        </div>
      ),
      width: "48px"
    }
  ];

  return (
    <Table
      pagination={false}
      dataSource={data}
      columns={columns}
      className={cx("footer_table", styles.product_table)}
      rowClassName={styles.row}
      loading={{
        spinning: isLoading,
        indicator: LoadingIndicator
      }}
      locale={{
        emptyText: <TableEmpty />
      }}
    />
  );
};

export default Products;
