import { useState } from "react";
import { DatePicker, Dropdown, Popover, Progress, Spin } from "antd";
import dayjs, { Dayjs } from "dayjs";
import { CheckIcon } from "features/app/assets/icons/CheckIcon";
import AppendEmployees from "features/app/components/append-employees/append-employees";
import ConfirmationUsersList from "features/app/components/confirmation-users-list/ConfirmationUsersList";
import { useTaskUpdate } from "features/projects/pages/detailed-project/pages/estimate/pages/gantt/services/mutation";
import { CompletedWorkModel } from "features/projects/utils/models/completedWorkModel";
import CalendarIcon from "features/supply/assets/icons/CalendarIcon";
import ChevronDownIcon from "features/supply/assets/icons/ChevronDownIcon";
import { useTranslation } from "react-i18next";

import { colors, cx } from "modules/common";

import { dayjsFormats } from "../../../../../../app/utils/constants/dayjsFormats";
import { getFirstLetter } from "../../../../../../app/utils/helpers/getFirstLetter";
import { useGetWork } from "../../../../../service/queries";
import { workStatusValue } from "../../../../../utils/constants/workStatusValue";
import { useDispatch } from "react-redux";
import { resourceSpendingActions } from "store";
import { WorkStatusEnum } from "../../../../../utils/enums/workStatusEnum";

import DotPointsIcon from "../../../../../assets/icons/DotPointsIcon";
import WorkPlan from "../plan/WorkPlan";
import WorkModalTabs from "../tabs/WorkModalTabs";
import PlusIcon from "features/app/assets/icons/PlusIcon";
import { WorkModel } from "features/projects/utils/models/workModel";

import styles from "./workModalLeft.module.scss";

type Props = {
  visible: boolean;
  id?: number;
  task?: WorkModel;
};

const WorkModalLeft = ({ visible, id, task }: Props) => {
  const { i18n, t } = useTranslation();
  const [progressId, setProgressId] = useState(0);
  const [openProgress, setOpenProgress] = useState(false);
  const [openPlan, setOpenPlan] = useState(false);
  const [foundTaskProgress, setFoundTaskProgress] = useState<CompletedWorkModel | null>(null);
  const { data: work } = useGetWork(visible, id);
  const taskUpdate = useTaskUpdate();
  const [isFieldLoading, setIsFieldLoading] = useState<"status" | "start_date" | "due_date" | "users">("status");

  const dispatch = useDispatch();
  const { setOpenDrawer } = resourceSpendingActions;

  const onStatusChange = (status: WorkStatusEnum) => {
    setIsFieldLoading("status");
    return taskUpdate.mutate({ status: status as never, id });
  };
  const onStartDateChange = (start_date: string) => {
    setIsFieldLoading("start_date");
    return taskUpdate.mutate({ start_date, id });
  };
  const onDueDateChange = (due_date: string) => {
    setIsFieldLoading("due_date");
    return taskUpdate.mutate({ due_date, id });
  };
  const onUsersAndCompanyPersonsChange = ({
    user_ids,
    company_person_ids
  }: {
    user_ids: number[];
    company_person_ids: number[];
  }) => {
    setIsFieldLoading("users");
    return taskUpdate.mutateAsync({ user_ids, company_person_ids, id });
  };

  const onOpenAllResources = () => {
    setProgressId(0);
    setOpenProgress(true);
  };

  const onOpenOneResource = (id: number) => {
    setProgressId(id);
    setFoundTaskProgress(work?.task_progresses?.find(el => el?.id === id)!);

    setOpenProgress(true);
  };

  const getIconColor = () => {
    if (work?.task?.status !== "open") return colors.WHITE;
    return "";
  };

  const getIconContainerBorderColor = () => ({
    "border-l-gray-300": work?.task?.status === WorkStatusEnum.OPEN,
    "border-l-gray-400": work?.task?.status === WorkStatusEnum.CLOSED,
    "border-l-primary-700": work?.task?.status === WorkStatusEnum.PROCESS,
    "border-l-success-700": work?.task?.status === WorkStatusEnum.COMPLETED
  });

  const statusItems = [
    {
      key: "1",
      label: (
        <div className="flex items-center justify-between">
          <div className="flex items-center gap-2">
            <span className="h-2 w-2 rounded-[50%] border border-solid border-gray-300 bg-gray-300" />
            <span className="text-base font-medium text-gray-900">{t("works.Ochiq")}</span>
          </div>
          {work?.task?.status === WorkStatusEnum.OPEN && <CheckIcon color={colors.GRAY_500} />}
        </div>
      ),
      onClick: () => onStatusChange(WorkStatusEnum.OPEN)
    },
    {
      key: "2",
      label: (
        <div className="flex items-center justify-between">
          <div className="flex items-center gap-2">
            <span className="h-2 w-2 rounded-[50%] border border-solid border-primary-500 bg-primary-500" />
            <span className="text-base font-medium text-gray-900">{t("works.Jarayonda")}</span>
          </div>
          {work?.task?.status === WorkStatusEnum.PROCESS && <CheckIcon color={colors.PRIMARY} />}
        </div>
      ),
      onClick: () => onStatusChange(WorkStatusEnum.PROCESS)
    },
    {
      key: "3",
      label: (
        <div className="flex items-center justify-between">
          <div className="flex items-center gap-2">
            <span className="h-2 w-2 rounded-[50%] border border-solid border-success-500 bg-success-500" />
            <span className="text-base font-medium text-gray-900">{t("works.Yakunlangan")}</span>
          </div>
          {work?.task?.status === WorkStatusEnum.COMPLETED && <CheckIcon color={colors.SUCCESS_500} />}
        </div>
      ),
      onClick: () => onStatusChange(WorkStatusEnum.COMPLETED)
    },
    {
      key: "4",
      label: (
        <div className="flex items-center justify-between">
          <div className="flex items-center gap-2">
            <span className="h-2 w-2 rounded-[50%] border border-solid border-success-600 bg-success-600" />
            <span className="text-base font-medium text-gray-900">{t("works.Yopilgan")}</span>
          </div>
          {work?.task?.status === WorkStatusEnum.CLOSED && <CheckIcon color={colors.SUCCESS_700} />}
        </div>
      ),
      onClick: () => onStatusChange(WorkStatusEnum.CLOSED)
    }
  ];

  const handleOpenResourceSpendingDrawer = () => {
    dispatch(
      setOpenDrawer({
        visible: true,
        unitName: work?.task?.unit?.symbol?.[i18n.language] as string,
        record: task as never
      })
    );
  };

  return (
    <div className={styles.left}>
      <div className="mb-5 text-2xl font-semibold text-gray-700">{work?.task?.name}</div>
      <div className={styles.info}>
        <div className={styles.status}>
          <p>{t("works.Holati")}</p>
          <Spin spinning={taskUpdate.isLoading && isFieldLoading === "status"}>
            <Dropdown rootClassName={styles.dropdown} trigger={["click"]} menu={{ items: statusItems }}>
              <div
                className={cx(
                  "mt-2 flex h-8 cursor-pointer items-center justify-between rounded-[6px]",
                  styles[work?.task?.status as WorkStatusEnum]
                )}
              >
                <span
                  className={cx(
                    styles[work?.task?.status as WorkStatusEnum],
                    "flex h-full items-center justify-center px-3"
                  )}
                >
                  {workStatusValue[work?.task?.status as WorkStatusEnum]}
                </span>
                <span
                  className={cx(
                    "flex h-full items-center justify-center border-b-0 border-l border-r-0 border-t-0 border-solid px-[6px]",
                    getIconContainerBorderColor()
                  )}
                >
                  <ChevronDownIcon rotate stroke={getIconColor()} />
                </span>
              </div>
            </Dropdown>
          </Spin>
        </div>
        <div className="flex flex-col gap-2">
          <p>{t("works.Boshlanish mudati")}</p>

          <Spin spinning={taskUpdate.isLoading && isFieldLoading === "start_date"}>
            <DatePicker
              className={styles.datePicker}
              value={work?.task?.start_date ? dayjs(work?.task?.start_date, dayjsFormats.DATE) : null}
              format={dayjsFormats.DATE}
              placeholder={t("works.Sanani tanglang")}
              suffixIcon={<CalendarIcon />}
              onChange={(date: Dayjs, dateString: string | string[]) => onStartDateChange(dateString as string)}
            />
          </Spin>
        </div>
        <div className="flex flex-col gap-2">
          <p>{t("works.Tugash mudati")}</p>

          <Spin spinning={taskUpdate.isLoading && isFieldLoading === "due_date"}>
            <DatePicker
              className={styles.datePicker}
              value={work?.task?.due_date ? dayjs(work?.task?.due_date, dayjsFormats.DATE) : null}
              format={dayjsFormats.DATE}
              placeholder={t("works.Sanani tanglang")}
              suffixIcon={<CalendarIcon />}
              onChange={(date: Dayjs, dateString: string | string[]) => onDueDateChange(dateString as string)}
            />
          </Spin>
        </div>
        <div className={styles.users_info}>
          <p>{t("works.Mas’ul xodim")}</p>

          <Spin spinning={taskUpdate.isLoading && isFieldLoading === "users"}>
            <AppendEmployees
              users={work?.task?.users || []}
              company_persons={work?.task?.company_persons || []}
              updateEmployees={onUsersAndCompanyPersonsChange}
            />
          </Spin>
        </div>
      </div>
      <div className={styles.works}>
        <p>Progress:</p>
        <div className={styles.end_works}>
          <div className={styles.end_works__volume}>
            <p>{t("works.Ish hajmi")}</p>
            <div>
              <h4>
                {work?.task?.quantity} <span>{work?.task?.unit?.symbol[i18n.language]}</span>
              </h4>
            </div>
          </div>
          <div className={styles.end_works__volume}>
            <p>{t("works.Bajarildi")}</p>
            <div>
              <h4>
                {work && work?.task?.task_progress_quantity} <span>{work?.task?.unit?.symbol[i18n.language]}</span>
              </h4>
            </div>
          </div>
          <div className={styles.end_works__resource} onClick={onOpenAllResources}>
            <div className={styles.end_works__inner}>
              <DotPointsIcon />
              <div>
                <h4>{t("works.Resurslar")}</h4>
              </div>
            </div>
          </div>
          <div
            className="flex w-[150px] cursor-pointer flex-col items-center justify-center gap-2.5 rounded-xl border-[2px] border-solid border-primary-600 bg-primary-500 px-4 py-2.5 text-sm font-medium text-white shadow-sm"
            onClick={handleOpenResourceSpendingDrawer}
          >
            <PlusIcon color={colors.WHITE} size={24} />
            {t("works.Ish qo'shish")}
          </div>
        </div>
      </div>
      <div className={styles.progress}>
        <Progress percent={work && work?.task?.task_percent} format={() => work && `${work?.task?.task_percent}%`} />
      </div>
      <div className={styles.work_progresses}>
        {work?.task_progresses?.map((item, index, arr) => (
          <div
            className="flex items-center gap-3 rounded-xl border border-solid border-gray-50 bg-gray-50 p-2"
            key={item.id}
          >
            <div
              className={cx(
                styles.work_progress,
                work?.task?.confirmation_users && work?.task?.confirmation_users?.length > 0 ? "w-[70%]" : "w-full"
              )}
              onClick={() => onOpenOneResource(item.id)}
            >
              <div className={styles.work_progress__left}>
                <span className={styles.work_progress__left_volume}>
                  {item?.quantity}{" "}
                  <span className={styles.work_progress__left_unit}>{work?.task?.unit?.symbol[i18n.language]}</span>
                </span>
                <span className={styles.work_progress__left_count}>
                  {t("works.Bajarildi")} №{arr.length - index}
                </span>
              </div>
              <div className={styles.work_progress__right}>
                <span className={styles.work_progress__right_work}>{item?.date}</span>
                <Popover title={item?.creator?.full_name} overlayClassName={styles.popover__confirmation}>
                  {item?.creator?.image ? (
                    <img src={item?.creator?.image} className={styles.work_progress__avatar} />
                  ) : (
                    <div className={styles.work_progress__avatar}>{getFirstLetter(item?.creator?.full_name)}</div>
                  )}
                </Popover>
              </div>
            </div>
            {work?.task?.confirmation_users && work?.task?.confirmation_users?.length > 0 && (
              <div className="flex w-1/4 items-center gap-6 rounded-xl border border-solid border-gray-200 bg-white p-4">
                <span className="text-sm font-medium text-gray-500">{t("works.Guvohlar")}</span>
                <ConfirmationUsersList record={work?.task as never} length={3} />
              </div>
            )}
          </div>
        ))}
      </div>
      <WorkModalTabs
        open={openProgress}
        setOpen={setOpenProgress}
        work={work?.task}
        foundTaskProgress={foundTaskProgress}
        progressId={progressId}
      />
      <WorkPlan open={openPlan} setOpen={setOpenPlan} id={work?.task?.id} />
    </div>
  );
};

export default WorkModalLeft;
