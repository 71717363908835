import React from "react";
import { useParams } from "react-router-dom";
import { parseParamsId } from "features/app/utils/helpers/parseParamsId";

import { useQueryParams } from "hooks/useQueryParams";

import { WorksTabEnum } from "modules/works";
import { percentCalculator } from "modules/works/helpers/percentCalulator";
import { useWorksStatistics } from "modules/works/queries/use-works-statistics";

import { Info } from "./info";
import { Tables } from "./tables";
import { ResourceSpendingDrawer } from "../drawers";
import WorkModal from "features/projects/components/detailed-project/work/modal/WorkModal";

const Bottom: React.FC = () => {
  const { queries } = useQueryParams();
  const { statistics_type } = queries();

  const params = useParams();
  const { id: projectId } = parseParamsId(params?.projectId);

  const { worksStatistics, isLoading } = useWorksStatistics({ enabled: true, projectId: Number(projectId) });

  const firstTotal =
    worksStatistics?.closed + worksStatistics?.completed + worksStatistics?.process + worksStatistics?.open;
  const secondTotal = worksStatistics?.pending + worksStatistics?.recieved + worksStatistics?.rejected;
  const fourthTotal = worksStatistics?.mechanism + worksStatistics?.person + worksStatistics?.product;

  const statistics: Record<string, Record<string, number>> = {
    [WorksTabEnum.LIST_OF_WORKS]: {
      open: worksStatistics?.open,
      in_progress: worksStatistics?.process,
      finished: worksStatistics?.completed,
      closed: worksStatistics?.closed,
      open_percent: percentCalculator(firstTotal, worksStatistics?.open),
      in_progress_percent: percentCalculator(firstTotal, worksStatistics?.process),
      finished_percent: percentCalculator(firstTotal, worksStatistics?.completed),
      closed_percent: percentCalculator(firstTotal, worksStatistics?.closed)
    },
    [WorksTabEnum.PROGRESSES]: {
      total_progress_works: secondTotal,
      total_progress_sections: worksStatistics?.pending,
      total_progress: worksStatistics?.recieved,
      average_work_progress: worksStatistics?.rejected
    },
    [WorksTabEnum.PROCESS]: {
      total_resource_amount: fourthTotal,
      person_resource_spent_amount: worksStatistics?.person,
      mechanism_resource_spent_amount: worksStatistics?.mechanism,
      product_resource_spent_amount: worksStatistics?.product
    }
  };

  return (
    <div className="box-border flex flex-col gap-4 p-4">
      <Info statistics={statistics[statistics_type]} isLoading={isLoading} />
      <Tables />
      <ResourceSpendingDrawer />
      <WorkModal />
    </div>
  );
};

export default Bottom;
