import React from "react";
import AddModal from "../add-modal";
import { useTranslation } from "react-i18next";
import { Button, FormInstance, Form } from "antd";
import { SpendingWorksForm } from "modules/works";

import PlusIcon from "features/app/assets/icons/PlusIcon";

type Props = {
  type: "product" | "person" | "mechanism";
  taskId: number | undefined;
};

const TabExtraContent: React.FC<Props> = ({ type, taskId }) => {
  const { t } = useTranslation();
  return (
    <AddModal title={t("works.Inson resursi")} type={type} taskId={taskId}>
      <Button icon={<PlusIcon />}>{t("warehouse.Resurs qo'shish")}</Button>
    </AddModal>
  );
};

export default TabExtraContent;
