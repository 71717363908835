import { useState } from "react";
import { Button, DatePicker, Form, Input, Modal, Select } from "antd";
import CustomAvatar from "features/app/components/custom-avatar/CustomAvatar";
import ProjectSelect from "features/app/components/project-select/project-select";
import { useProjectSelect } from "features/app/service/queries";
import { ProjectSelectModel } from "features/app/utils/models/projectSelectModel";
import { useTranslation } from "react-i18next";

import { useAppDispatch, useAppSelector } from "hooks/redux";

import { inventoryReducerActions } from "store/reducers/inventoryReducer";

import { useAuth } from "modules/auth/hooks";
import { cx, dayjsFormats } from "modules/common";

import CloseIcon from "../../../app/assets/icons/CloseIcon";
import PlusIcon from "../../../app/assets/icons/PlusIcon";
import SelectSuffixIcon from "../../../app/assets/icons/SelectSuffixIcon";
import { AppendEmployee } from "../../../app/components/append-employee/AppendEmployee";
import CustomUpload from "../../../app/components/custom-upload/CustomUpload";
import { formRules } from "../../../app/utils/constants/formRules";
import { getFirstLetter } from "../../../app/utils/helpers/getFirstLetter";
import { isEmptyArr } from "../../../app/utils/helpers/isEmptyArr";
import { FileListType } from "../../../app/utils/models/fileListModel";
import { UserSelectModel } from "../../../app/utils/models/user/userSelectModel";
import { useCreateDefect, useUpdateDefect } from "../../service/mutations";
import { defectTypes } from "../../utils/constants/defectTypes";
import { DefectTypesEnum } from "../../utils/enums/defectTypesEnum";
import { DefectBodyModel } from "../../utils/models/defectBodyModel";

import styles from "./defectModal.module.scss";
import { CalendarPlusIcon } from "components";

const { Item, useForm } = Form;
const { Option } = Select;
const { TextArea } = Input;

type SetUserType = (data: UserSelectModel | UserSelectModel[] | undefined) => void;

const fileAccept = ".jpg, .jpeg, .png, .svg";

const DefectModal = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [form] = useForm<DefectBodyModel>();
  const [usersState, setUsersState] = useState<UserSelectModel[] | undefined>([]);

  const [fileList, setFileList] = useState<FileListType[]>([]);
  const { visible, product } = useAppSelector(state => state.inventoryReducer.defectModalData);
  const userId = useAuth().user?.id;
  const { setDefectModalData, setInventoryDetailModalData } = inventoryReducerActions;
  const { data: projects } = useProjectSelect({ enabled: visible });
  const createDefect = useCreateDefect();
  const updateDefect = useUpdateDefect();

  const onAfterOpen = (visible: boolean) => {
    if (visible && product) {
      if (product?.inventory?.id) {
        form.setFieldsValue({
          id: product.id,
          project_id: product?.project?.id,
          type: product.type,
          confirmation_user_ids: product.confirmation_users.map(item => item.id),
          description: product?.description
        });
        setUsersState(product?.confirmation_users?.map(item => item.user));
      }
    }
  };

  const onOk = () => {
    form.submit();
  };

  const onCancel = () => {
    dispatch(setDefectModalData({ visible: false, product: null }));
    form.resetFields();

    setUsersState([]);
  };

  const onFinish = (values: DefectBodyModel) => {
    if (product?.inventory?.id) {
      updateDefect
        .mutateAsync({
          id: values.id,
          project_id: values.project_id!,
          type: values.type,
          confirmation_user_ids: values.confirmation_user_ids,
          description: values.description,
          file_ids: fileList.map(el => el.id)
        })
        .then(() => onCancel());
    } else {
      createDefect
        .mutateAsync({
          ...values,
          user_id: userId
        })
        .then(() => {
          dispatch(setInventoryDetailModalData({ visible: false, inventory_id: undefined }));
          onCancel();
        });
    }
  };

  const getProjects = (): ProjectSelectModel[] | undefined => {
    const isProject = projects?.some(
      parent =>
        parent?.id === product?.project?.id || parent?.projects?.some(project => project?.id === product?.project?.id)
    );

    if (!isProject && projects && product?.project) {
      return [product.project!, ...projects!];
    }

    return projects;
  };

  return (
    <Modal
      centered
      onOk={onOk}
      open={visible}
      onCancel={onCancel}
      closeIcon={<CloseIcon />}
      width={738}
      okButtonProps={{
        loading: createDefect.isLoading || updateDefect.isLoading
      }}
      title={t("Inventar.Yaroqsizga chiqarish")}
      cancelText={t("products.Yopish")}
      okText={t("products.Saqlash")}
      className={styles.invalid}
      afterOpenChange={onAfterOpen}
      zIndex={1001}
    >
      <Form form={form} onFinish={onFinish} layout="vertical" className={styles.form}>
        <Item<DefectBodyModel> name="user_id" initialValue={userId} className="hidden" />
        <Item<DefectBodyModel> name="id" initialValue={product?.id} rules={formRules()}>
          <div className={styles.product}>
            <div className={cx("resource")}>
              <div className={styles.resource}>MEX</div>
              <span>{product?.name || product?.inventory?.name}</span>
              <span>({product?.code || product?.inventory?.name})</span>
            </div>
            <div className={styles.product__right}>{getFirstLetter(product?.company_person?.name ?? "")}</div>
          </div>
        </Item>
        <ProjectSelect
          enabled={visible}
          label={t("MainLayoutLinks.Loyiha")}
          name="project_id"
          mode="form"
          rule={formRules()}
          payloadData={getProjects()}
        />
        <Item<DefectBodyModel> name="type" label={t("Inventar.Yaroqsizlik turi")} rules={formRules()}>
          <Select suffixIcon={<SelectSuffixIcon />} placeholder={t("Inventar.Tanlang")}>
            {Object.keys(defectTypes).map((item, index) => (
              <Option key={index} value={item}>
                <span>{t(`Inventar.${defectTypes[item as DefectTypesEnum]}`)}</span>
              </Option>
            ))}
          </Select>
        </Item>
        <div className={styles.form__block}>
          <AppendEmployee
            data={usersState}
            setData={setUsersState as SetUserType}
            formInstance={form}
            isConfirmationUser={
              product?.inventory?.id
                ? {
                    object_id: product?.id,
                    type: "inventory_defects"
                  }
                : undefined
            }
            name="confirmation_user_ids"
            notMe
          >
            <Item<DefectBodyModel>
              label={t("Inventar.Guvohlar")}
              name="confirmation_user_ids"
              className={styles.users_cont}
              rules={formRules()}
            >
              {isEmptyArr(usersState) ? (
                <Button className={cx("add_user")} type="dashed" shape="circle">
                  <PlusIcon />
                </Button>
              ) : (
                <div className={styles.users}>
                  {usersState &&
                    usersState?.map(user => <CustomAvatar key={user?.id} image={user?.image} name={user?.full_name} />)}
                </div>
              )}
            </Item>
          </AppendEmployee>
        </div>
        <>
          <Item<DefectBodyModel> label={t("Xodimlar.Izoh")} name="description">
            <TextArea rows={4} autoSize={false} placeholder={t("Xodimlar.Izoh")} />
          </Item>
          <div className={styles.upload}>
            <CustomUpload
              isArray
              isImage
              form={form}
              name="file_ids"
              fileList={fileList}
              accept={fileAccept}
              setFileList={setFileList}
            />
          </div>
        </>
      </Form>
    </Modal>
  );
};

export default DefectModal;
