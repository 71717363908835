import React from "react";
import PaymentSearch from "features/payment/components/top/search/PaymentSearch";

import { Button } from "antd";
import { useQueryParams } from "hooks/useQueryParams";
import { FilterDrawers } from "./filter-drawers";
import { useTranslation } from "react-i18next";
import { resourceSpendingActions, useDispatch } from "store";

import WorksTabEnums from "modules/works/constants/worksTabEnums";

import Actions from "./actions";
import PlusIcon from "features/app/assets/icons/PlusIcon";

const Right: React.FC = () => {
  const dispatch = useDispatch();
  const { queries } = useQueryParams();
  const { statistics_type } = queries();
  const { t } = useTranslation();
  const { setOpenDrawer } = resourceSpendingActions;

  const handleOpenWorkDrawer = () => {
    dispatch(
      setOpenDrawer({
        record: undefined,
        unitName: "",
        visible: true
      })
    );
  };

  return (
    <div className="box-border flex items-center gap-3 px-4 py-2">
      {statistics_type === WorksTabEnums.PROGRESSES && (
        <Button icon={<PlusIcon />} onClick={handleOpenWorkDrawer}>
          {t("works.Ish bajarish")}
        </Button>
      )}
      <PaymentSearch />
      <FilterDrawers />
      {statistics_type === WorksTabEnums.PROCESS && <Actions />}
    </div>
  );
};

export default Right;
