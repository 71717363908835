import React, { useMemo, useCallback } from "react";
import { FormInstance, Form, Input, Select } from "antd";
import { useTranslation } from "react-i18next";

import { CurrencyModel } from "features/settings/utils/models/currency/currencyModel";
import { SpendingWorksByIdData } from "modules/works";

import styles from "./index.module.scss";

const { useWatch } = Form;

type Product = {
  id: number;
  currency_id: number;
  quantity: number | undefined;
  company_person_id?: number;
  user_id?: number;
  inventory_id?: number;
  amount: number | undefined;
};

type Props = {
  form: FormInstance<any>;
  type: "person_details" | "mechanism_details";
  record: SpendingWorksByIdData;
  task_product_id: number;
  currencies: CurrencyModel[] | undefined;
};

const QuantityInput: React.FC<Props> = ({ form, type, record, task_product_id, currencies }) => {
  const products = useWatch(type, form) as Product[];
  const { t } = useTranslation();

  const findProduct = useCallback(() => {
    return products?.find(product =>
      type === "person_details"
        ? record?.user?.id
          ? product.user_id === record.user.id && product?.id === task_product_id
          : product.company_person_id === record?.company_person?.id && product?.id === task_product_id
        : product.inventory_id === record?.inventory?.id && product.id === task_product_id
    );
  }, [products, type, record]);

  const updateProducts = useCallback(
    (newQuantity: number, newCurrencyId?: number) => {
      const existingProduct = findProduct();

      if (newQuantity > 0 || newCurrencyId) {
        if (!existingProduct) {
          const newProduct: Product = {
            id: task_product_id,
            amount: undefined,
            quantity: newQuantity || 0,
            currency_id: newCurrencyId || record?.currency?.id! || (currencies?.[0]?.id as number),
            ...(type === "person_details"
              ? record?.user
                ? { user_id: record.user.id }
                : { company_person_id: record?.company_person?.id as never }
              : { inventory_id: record?.inventory?.id as never })
          };
          form.setFieldsValue({ [type]: [...(products || []), newProduct] });
        } else {
          const updatedProducts = products.map(product =>
            product === existingProduct
              ? {
                  ...product,
                  quantity: newQuantity || product.quantity,
                  currency_id: newCurrencyId || product.currency_id
                }
              : product
          );
          form.setFieldsValue({ [type]: updatedProducts });
        }
      } else {
        let filteredProducts = [];
        if (!existingProduct?.amount) {
          filteredProducts = products.filter(product => product !== existingProduct);
        } else {
          filteredProducts = products?.map(product =>
            product === existingProduct
              ? {
                  ...product,
                  quantity: undefined
                }
              : product
          );
        }
        form.setFieldsValue({ [type]: filteredProducts });
      }
    },
    [findProduct, form, products, task_product_id, type, record]
  );

  const defaultValue = useMemo(() => findProduct()?.quantity || "", [findProduct]);

  return (
    <Input
      className={styles.quantity_input}
      placeholder={t("works.Qiymat")}
      onChange={e => updateProducts(Number(e.target.value))}
      value={defaultValue}
    />
  );
};

export default QuantityInput;
