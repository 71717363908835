import React, { useEffect } from "react";
import { Form, FormInstance, Input } from "antd";
import { useTranslation } from "react-i18next";

import ProjectSelect from "features/app/components/project-select/project-select";
import WorkSelect from "features/app/components/work-select";
import { useAppSelector } from "hooks/redux";
import { cx, formRules, localeFormatter } from "modules/common";
import { useQueryParams } from "hooks/useQueryParams";
import { queryParamsKeys } from "features/app/utils/constants/queryParamsKeys";
import { useGetWork } from "features/projects/service/queries";
import Percent from "./percent";

const { Item, useWatch } = Form;

type Props = {
  form: FormInstance<any>;
};

const Top: React.FC<Props> = ({ form }) => {
  const { t } = useTranslation();
  const projectId = useWatch("project_id", form);
  const { queries, severalSearchParams } = useQueryParams();
  const { statistics_type, search, page } = queries();
  const filters = severalSearchParams(queryParamsKeys.STATISTICS_TYPE, queryParamsKeys.SEARCH, queryParamsKeys.PAGE);

  const record = useAppSelector(state => state.resourceSpendingReducer.drawer?.record);
  const unitName = useAppSelector(state => state.resourceSpendingReducer?.drawer?.unitName);
  const open = useAppSelector(state => state.resourceSpendingReducer?.drawer?.visible);
  const unit_name = useWatch("unit_name", form);
  const taskId = useWatch("task_id", form);

  const handleQuantityInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    form.setFieldValue("quantity", localeFormatter(event?.target?.value));
  };

  const { data: work } = useGetWork(!!(!record && taskId && open), taskId);

  useEffect(() => {
    if (projectId) {
      form.setFieldsValue({ task_id: undefined });
    }
  }, [projectId]);

  return (
    <div className={cx("flex items-center gap-3 rounded-2xl border border-solid border-gray-200 bg-white p-3")}>
      {!record && (
        <ProjectSelect
          allowClear
          mode="form"
          name="project_id"
          label={t("works.Loyiha")}
          form={form}
          rootClassName="flex-1"
        />
      )}

      <WorkSelect
        name="task_id"
        formItemClassName={cx(!record ? "flex-1" : "w-1/2")}
        project_id={projectId}
        disabled={!!record}
        cominSearch={statistics_type === "task" && search ? search : undefined}
        page={statistics_type === "task" && page ? (page as never) : undefined}
        filters={statistics_type === "task" && filters ? filters : undefined}
        record={record as never}
        form={form}
      />
      <Item
        name="quantity"
        label={t("works.Progress")}
        className={cx(!record ? "w-[224px]" : "flex-1")}
        rules={formRules()}
      >
        <Input suffix={unitName || unit_name} placeholder={t("works.Kiriting")} onChange={handleQuantityInputChange} />
      </Item>
      <Percent record={record || (work?.task as never)} />
    </div>
  );
};

export default Top;
